*,
::after,
::before {
  box-sizing: border-box;
  margin:  0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body a {
  text-decoration: none;
}

input:focus ,  input {
  outline : none
}

form  {
  width : 100%
}

.Mui-checked,.MuiCheckbox-colorSecondary.Mui-checked {
  color: "#3D857E"
}

.uppercase {
  text-transform: uppercase;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --secondary-black: #333333;
  --creame-color: #f9f6ee;
  --dark-creame-color: #e7dcc1;
  --light-creame-color: #f8f3e8;
  --backgroun-color: #fafafa;
  --border-color: #E2E2E2;
  --disabled-color: #B0C1B7;
  --max-width: 1200px;
  --green-color : #004236;
  --light-green : #e6eeed;
  --text-color: #f2f2f2;
  --grey-color : #666666;
  --box-shadow-div: 0px 0px 30px rgba(146, 146, 146, 0.1);
}
